<template>
    <div id="study" class="study" :class="mode === 'phone' ? 'mobile-study' : ''">
        <div class="container" v-if="!loading">
            <VideoModule :mode="mode" @playEnd="onVideoEnded" :currentCourse="currentCourse" />
            <div
                class="course-btn"
                v-if="mode === 'computer'"
                @click="isShowMenu = !isShowMenu"
                :class="isShowMenu ? '' : 'right-course-btn'"
            >
                <van-icon v-show="!isShowMenu" name="arrow-left" />
                <van-icon v-show="isShowMenu" name="arrow" />
            </div>
            <div
                class="course-container"
                :class="isShowMenu ? '' : 'hide-course-container'"
                :style="`background-image: url(${watermarkData});`"
            >
                <div class="title">
                    <div
                        @click="onSwitchTab(item.val)"
                        :class="activeTab === item.val ? 'active-title' : ''"
                        v-for="item in productDetail.tabItems"
                        :key="item.val"
                    >
                        {{ item.n }}
                    </div>
                </div>
                <ListModule
                    class="list-module"
                    @initTab="onSwitchTab"
                    @courseChange="onCourseChange"
                    :currentCourse="currentCourse"
                    :activeTab="activeTab"
                    :listData="listData"
                    :endedKey="endedKey"
                    :product="productDetail"
                />
            </div>
        </div>
        <WatermarkBg v-if="!loading" moduleType="study" @success="onUpdateWatermarkData" />
        <Watermark />
        <AgmAffirm :isShow.sync="isShowAgmAffirm" :agmInfo="agmInfo" />
    </div>
</template>
<script>
import WatermarkBg from '@/components/WatermarkBg'
import Watermark from '@/components/Watermark'
import VideoModule from './components/VideoModule.vue'
import ListModule from './components/ListModule.vue'
import AgmAffirm from './components/AgmAffirm.vue'
import { coursePackageList } from '@/api/courseApi.js'
import { learnPackages, getPackageStage } from '@/api/studyApi.js'
import { getAgreementStates, coursePackageUserList } from '@/api/userApi.js'
import { mapGetters } from 'vuex'

export default {
    name: 'study',
    components: { VideoModule, ListModule, Watermark, AgmAffirm, WatermarkBg },
    data() {
        return {
            productDetail: null,
            listData: [],
            activeTab: '',
            currentCourse: null,
            endedKey: 1,

            isShowMenu: true,
            loading: true,
            isShowAgmAffirm: false,
            agmInfo: null,
            mode: 'computer',
            watermarkData: '',
        }
    },
    computed: {
        ...mapGetters(['userInfo', 'deviceInfo']),
    },
    mounted() {
        this.ininPackageData()
        if (this.deviceInfo.mobile) {
            if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
                this.mode = 'phone'
            }
        }
        window.onresize = () => {
            this.mode = 'computer'
            if (this.deviceInfo.mobile) {
                if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
                    this.mode = 'phone'
                }
            }
        }
    },
    destroyed() {
        window.onresize = null
    },
    methods: {
        onUpdateWatermarkData(data) {
            this.watermarkData = data
        },
        onSwitchTab(tab) {
            if (this.activeTab !== tab) {
                this.activeTab = tab
            }
        },
        onCourseChange(course) {
            this.currentCourse = course
        },
        onVideoEnded() {
            this.endedKey += 1
        },
        async ininPackageData() {
            // 获取产品包详情
            const { packageNo } = this.$route.params
            const product = await coursePackageList(packageNo)
            if (product.data && product.data.length !== 0) {
                this.productDetail = product.data[0]
                // 获取产品包下全部课程[正课2、精品课3、直播答疑4]
                const allPackage = await learnPackages(this.productDetail.id)
                let packageItem = null
                const boutiqueList = []
                const liveList = []
                allPackage.data.forEach((item) => {
                    if (item.type === 3 && item.provider === 'video') {
                        boutiqueList.push({
                            id: `menu${item.id}_3`,
                            packageId: item.id,
                            packageNo: item.packageNo,
                            type: 3,
                            title: item.title,
                            provider: item.provider,
                            showTab: 'b',
                            unlocked: true,
                        })
                    } else if (item.type === 2) {
                        packageItem = item
                    } else if (item.provider === 'talkfun') {
                        const startTime = item.startTime ? item.startTime.replace(/-/g, '/') : ''
                        liveList.push({
                            id: `menu${item.id}_live`,
                            packageId: item.id,
                            packageNo: item.packageNo,
                            type: 'live',
                            title: item.title,
                            provider: item.provider,
                            showTab: 'live',
                            startTime: new Date(startTime).valueOf(),
                            unlocked: true,
                        })
                    }
                })
                // 如果有正课则获取对应阶段
                const stageList = []
                if (packageItem) {
                    const stage = await getPackageStage(packageItem.id)
                    stage.data.forEach((item) => {
                        const itemStage = {
                            id: `menu${item.id}_2`,
                            packageId: packageItem.id,
                            packageNo: packageItem.packageNo,
                            provider: packageItem.provider,
                            stageId: item.id,
                            type: 2,
                            title: item.title,
                            showTab: 'p',
                            unlocked: item.unlocked,
                        }
                        stageList.push(itemStage)
                    })
                }
                this.listData = [...stageList, ...boutiqueList, ...liveList]
                // tabType stage: 阶段 1: 精品 2: 直播
                this.productDetail.tabItems = []
                if (stageList.length !== 0) {
                    this.productDetail.tabItems.push({ n: '阶段课程', val: 'p' })
                }
                if (liveList.length !== 0) {
                    this.productDetail.tabItems.push({ n: '直播课', val: 'live' })
                }
                if (boutiqueList.length !== 0) {
                    this.productDetail.tabItems.push({ n: '精品课', val: 'b' })
                }
                this.loading = false
            } else {
                this.$router.push('/course')
            }
            // 身份证验证
            this.getAgreementStatesApi()
        },
        getAgreementStatesApi() {
            const { packageNo } = this.$route.params
            coursePackageUserList({ packageNo }).then((res) => {
                const orderNo = res.data[0]['orderNo']
                getAgreementStates({ orderNos: [orderNo] }).then((state) => {
                    if (state.data[0] && state.data[0]['needCertNo']) {
                        this.agmInfo = {
                            ...state.data[0],
                            orderNo: orderNo,
                            agmId: this.productDetail.agreementId,
                        }
                        this.isShowAgmAffirm = state.data[0]['needCertNo']
                    }
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    width: 100vw;
    height: 100vh;
}
.course-btn {
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 318px;
    transform: translateY(-50%);
    width: 16px;
    height: 84px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0px 20px 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &.right-course-btn {
        right: 0;
        border-radius: 20px 0 0 20px;
        background: rgba(0, 0, 0, 0.3);
    }
}
.course-container {
    background-color: #37393d;
    width: 334px;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    &.hide-course-container {
        width: 0;
    }
    .title {
        width: 334px;
        height: 64px;
        background: rgba(0, 0, 0, 4);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #262930;
        > div {
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ccc;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer;
        }
        .active-title {
            color: #f0941d;
        }
    }
}

// 移动端适配
.mobile-study {
    .container {
        flex-direction: column;
    }
    .video-container {
        height: calc(56.25vw + 96px);
    }
    .course-container {
        width: 100%;
        height: calc(100vh - 56.25vw - 96px);
        min-height: 300px;
        .title {
            width: 100%;
            height: 42px;
        }
    }
    .list-module {
        top: 58px;
        left: 16px;
        bottom: 16px;
        right: 16px;
    }
}
.canvas {
    background: #37393d;
}
</style>
