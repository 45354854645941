<template>
    <div :class="itemClass" class="video-title">
        <span class="video-title-text">{{ course.title }}</span>
        <div class="attendScale" v-if="course.unlocked && course.provider === 'video'">
            {{ course.attendScale ? `${course.attendScale}%` : '0%' }}
        </div>
        <div class="live-status" v-if="course.provider === 'talkfun'">
            <span v-if="course.status === 0">{{ course.startTime.substr(5, 11) }}</span>
            <span v-else-if="course.status === 1">直播中</span>
            <span v-else-if="course.status === 2">回放生成中</span>
            <span v-else-if="course.status === 3">{{ course.attendText || '-' }}</span>
        </div>
        <img
            v-if="!course.unlocked"
            class="suo-icon"
            src="https://res.peki.vip/20230317/a33401368bbb445b8c16747ddcde6425.png"
        />
        <img
            v-if="course.paperId && course.unlocked"
            @click.stop="toPaperPage(course)"
            class="homework-icon"
            src="https://res.peki.vip/20230317/275b439938b141c3b02d9681935ccfb6.png"
        />
        <img
            v-else-if="course.paperId && !course.unlocked"
            class="homework-icon"
            src="https://res.peki.vip/20230317/9037e7a09e0546f2b9aff8235e00308f.png"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    props: ['currentCourse', 'course'],
    data() {
        return {
            moduleList: [],
            lastCourse: null,
        }
    },
    computed: {
        itemClass: function () {
            const { currentCourse, course } = this
            const active = currentCourse && this.currentCourse.id === course.id ? 'active-course' : ''
            const unlocked = !course.unlocked ? 'unlocked-course' : ''
            return `course-item ${active} ${unlocked}`
        },
        ...mapGetters(['PROJECT_PATH']),
    },
    methods: {
        toPaperPage(item) {
            if (item.unlocked) {
                const { id, paperId } = item
                const url = `${this.PROJECT_PATH}/paper-pc.html?paperId=${paperId}&courseId=${id}`
                window.open(url, '_blank')
            } else {
                this.$toast('解锁课程后，才可作答～')
            }
        },
    },
}
</script>
<style scoped lang="scss">
.course-item {
    padding: 0 16px;
    height: 44px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    box-sizing: border-box;
    .video-title-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-basis: 72%;
    }
    .suo-icon {
        width: 18px;
        background: none;
        margin: 8px;
        flex-shrink: 0;
    }
    .homework-icon {
        margin-left: 8px;
        background: none;
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
    .live-status {
        flex-shrink: 0;
    }
}
.active-course {
    background: rgba(255, 255, 255, 0.12);
    color: #f0941d;
    font-weight: 700;
}
.unlocked-course {
    cursor: not-allowed;
}
</style>
